<template>
  <div>
    <div class="index-carousel">
      <div class="main masthead position-relative">
        <div class="masthead-overlay"></div>

        <div class="height-100 pt-2">
          <div class="container">
            <div class="row align-items-center">
              <!-- Left Side Text Content -->
              <div class="col-lg-6 col-md-6">
                <div class="masthead-cont">
                  <h3 class="masthead-title">
                    Create Stunning Visuals Using custom-trained AI Agents
                  </h3>
                </div>
                <div class="masthead-cont">
                  <h3 class="masthead-subtitle">
                    Turn ordinary images into eye-catching marketing visuals.
                    <br><br>
                    <strong>Quick Photoshoot:</strong> <br><br>Upload your images and train your agent, then you can start creating professional-grade marketing visuals in seconds ready to impress your customers.
                    <br><br>
                    Our AI enhances your product images with creative backgrounds, lighting, and effects to make your products stand out.
                    <br><br>
                  </h3>
                </div>

                <b-button 
                  :to="{ name: 'agents'}" 
                  pill 
                  variant="secondary" 
                  class="custom-button" 
                  style="margin-left: 30px; margin-bottom: 0px; margin-top: 10px;">
                  Try It Now
                </b-button>
              </div>
              
               <!-- Right Side Featured Images Carousel -->
              <!-- Right Side Featured Images Carousel -->
    <div class="col-lg-6 col-md-6 pt- ">
      <div class="featured-carousel">
        <carousel 
          :autoplay="true" 
          :autoplay-timeout="2000" 
          :loop="true" 
          :navigation-enabled="false" 
          :per-page="1"
          :pagination-enabled="false">
          <slide v-for="(pair, index) in imagePairs" :key="index">
            <div class="item">
              <div class="image-pair">
                <div class="image-container">
                  <img :src="pair.originalImage" alt="Original Image" class="carousel-image" />
                  <p class="image-label">Original</p>
                </div>
                <div class="image-container">
                  <img :src="pair.transformedImage" alt="Transformed Image" class="carousel-image" />
                  <p class="image-label">Transformed</p>
                </div>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BContainer,
  BRow,
  BCol,
  BImg,
  BLink,
} from 'bootstrap-vue';
import { Carousel, Slide } from 'vue-carousel';
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    BCard,
    BButton,
    BContainer,
    BRow,
    BCol,
    BImg,
    BLink,
    Carousel,
    Slide,
  },
  data() {
    return {
      isLoading: false,
      imagePairs: [
        {
          originalImage: 'https://media.briks.ai/images/bottle.jpg',
          transformedImage: 'https://media.briks.ai/images/water_bottle.jpg',
        },
        {
          originalImage: 'https://media.briks.ai/images/blue_dress.jpg',
          transformedImage: 'https://media.briks.ai/images/blue_dress_changed.png',
        },
        {
          originalImage: 'https://media.briks.ai/images/IMG_7759.jpg',
          transformedImage: 'https://media.briks.ai/images/image-7ad11942-74f5-47e8-9182-02781c391382.jpg',
        },
      
        {
          originalImage: 'https://media.briks.ai/images/430789649_7206795366100161_2780917370850811012_n.jpg',
          transformedImage: 'https://media.briks.ai/images/ComfyUI_temp_hpvhk_00011_.png',
        },
        {
          originalImage: 'https://media.briks.ai/images/430789649_7206795366100161_2780917370850811012_n.jpg',
          transformedImage: 'https://media.briks.ai/images/ComfyUI_temp_hpvhk_00012_.png',
        },
        {
          originalImage: 'https://media.briks.ai/images/image-03a55018-cd0e-4a93-9b0d-ebf310196143.jpg',
          transformedImage: 'https://media.briks.ai/images/image-153f5b78-61a8-4ddf-8e2f-62005d649f95.jpg',
        },   
        {
          originalImage: 'https://media.briks.ai/images/image_18_512.jpg',
          transformedImage: 'https://media.briks.ai/images/image-a0a0519f-c352-4495-9ad7-14006d69a28d.jpg',
        },    
        {
          originalImage: 'https://media.briks.ai/images/sam_19%20copy.jpg',
          transformedImage: 'https://media.briks.ai/images/image-756cbdbd-058f-4ce7-8603-128c27c20385.jpg',
        },  
        
        // Add more image pairs as needed
      ],
    };
  },
  // computed: {
  //   ...mapState('app', {
  //     // Banner: state => state.banner,
  //     FeaturedImages: state => state.featured_images,
  //   }),
  // },
  // mounted() {
  //   // this.loadFeaturedImagesFromStorage();
  //   // this.getBannerDetails();
  //   // this.fetchFeaturedItems();
  // },
  methods: {
    ...mapActions('app', [
      // 'getBannerDetails',
      // 'fetchFeaturedItems',
      // 'loadFeaturedImagesFromStorage',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.masthead {
  background: url('~@/assets/images/banner/bg.png'); 
  background-position: 0 0;
  background-repeat: repeat;
  background-size: auto;
  padding: auto;

}

.masthead-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(130deg, #000000 5%, #000 50%);
  background-position: top center;
  background-repeat: repeat;
  background-size: contain;
  opacity: 0.84;
}

.masthead-title {
  color: white; 
  font-size: 2.4rem; 
  padding-left: 30px; 
  padding-top: 0px; 
  line-height: 1.2;
  white-space: normal;
  overflow: visible;
}

.masthead-subtitle {
  color: white; 
  font-size: 1.3rem; 
  padding-left: 30px; 
  padding-top: 10px;
  line-height: 1.2;
  white-space: normal;
  overflow: visible;
}
.masthead-subtitle strong {
  font-weight: bold;
  color: #ffffff; /* Highlighted text in a bold, eye-catching color */
}

.masthead-subtitle strong:hover {
  color: #ffffff; /* Slight hover effect for better interaction */
}

.featured-carousel {
  width: auto; 
  max-height: 600px; 
}

.item {
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  height: 100%; 
  width: 100%;
  padding: 5px;
}

.image-pair {
  display: flex;
  flex-direction: row;       
  justify-content: center;   
  align-items: flex-start;   
  width: 100%;
}

.image-container {
  flex: 1;
  text-align: center;
  align-items: center;   
}

.carousel-image {
  width: 100%;              
  height: auto;
  max-width: 100%;
  object-fit: contain;      
  border: 0px solid #ddd;
  border-radius: 0px;
}

.image-label {
  margin-top: 5px;
  font-weight: bold;
  color: #ffffff;
}

.custom-button {
  margin-left: 30px;
  margin-bottom: 0px;
  margin-top: 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .masthead-title {
    font-size: 1.8rem;
    font-weight: bold;
    padding-top: 80px;
  }

  .masthead-title {
  color: white; 
  font-size: 2.4rem; 
  padding-left: 20px; 
  padding-top: 0px; 
  line-height: 1.2;
  white-space: normal;
  overflow: visible;
}

  .masthead-subtitle {
    font-size: 1.2rem;
    padding-left: 20px; 
  }

  .image-container {
    width: 100%;
    margin: 5px 0;
  }

  .carousel-image {
    width: 100%;
    max-width: 100%;
    height: auto;
    object-fit: contain;
    max-height: 250px; 
  }

  .custom-button {
    padding-left: 20px !important;
    margin-top: 5px; /* Adjust the margin as per need */
  }



  .item {
    width: 100%;
    height: auto;
  }

  .featured-carousel {
    padding: 10px;
  }  
}

@media (max-width: 576px) {
  /* Additional adjustments for smaller screens */
  .masthead-title {
    font-size: 1.5rem;
    padding-top: 60px;
  }

  .masthead-subtitle {
    font-size: 1rem;
  }



}
</style>
